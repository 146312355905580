import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Layout from '../../../layouts/mdx';
import OAuth2CodeFlowSnippet from '../../../snippets/oauth2-code-flow.mdx';
import LoginMethodsSnippet from '../../../snippets/login-methods.mdx';
import TestUsersSnippet from '../../../snippets/test-users.mdx';
export const _frontmatter = {
  "product": "verify",
  "category": "Integrations",
  "sort": 3,
  "title": "Okta",
  "subtitle": "This tutorial demonstrates how to integrate Criipto Verify with Okta"
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Highlight = makeShortcode("Highlight");
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`This tutorial demonstrates how to integrate Criipto Verify with Okta. The following steps are required to complete your first login:`}</p>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#register-your-okta-tenant-in-criipto-verify"
        }}>{`Register your Okta tenant in Criipto Verify`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#configure-the-oauth2-code-flow"
        }}>{`Configure your OAuth2 flow`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#create-okta-identity-provider"
        }}>{`Create Criipto Verify identity provider in Okta`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#integrate-your-own-application-with-okta"
        }}>{`Integrate your application with Okta`}</a></li>
    </ol>
    <p>{`In the following you will be configuring first Criipto Verify, then Okta.
Once configured you may test that everything works from Okta.`}</p>
    <p>{`The setup requires a bit of switching back-and-forth between Criipto and Okta's respective management dashboards, so we recommend that you have them open simultaneously to make the process really smooth.`}</p>
    <p>{`Before you get started, you will need the following information:`}</p>
    <ul>
      <li parentName="ul">{`The callback URL for your Okta tenant - this will depend on the DNS name you use in Okta for running your logins. The value will probably look something like `}<inlineCode parentName="li">{`https://your-company-name.okta.com/oauth2/v1/authorize/callback`}</inlineCode>{`, but check your Okta settings to make sure. We have used `}<inlineCode parentName="li">{`criipto-samples`}</inlineCode>{` as a replacement for `}<inlineCode parentName="li">{`your-company-name`}</inlineCode>{` in this tutorial.`}</li>
      <li parentName="ul"><em parentName="li">{`[Optional]`}</em>{` The `}<inlineCode parentName="li">{`post_logout_redirect_url`}</inlineCode>{` for your Okta tenant.`}</li>
    </ul>
    <h2>{`Register your Okta tenant in Criipto Verify`}</h2>
    <p>{`First, you must register your Okta tenant as an application in Criipto Verify.`}</p>
    <p>{`Once you register your Okta tenant, you will also need some of the information for configuring Okta to communicate with Criipto Verify. You get these details from the settings of the application in the dashboard.`}</p>
    <p>{`Specifically you need the following information to integrate with Okta:`}</p>
    <ul>
      <li parentName="ul"><em parentName="li">{`Client ID`}</em>{` to identify your Okta tenant to Criipto Verify. In the case below we chose `}<inlineCode parentName="li">{`urn:criipto:verify`}</inlineCode></li>
      <li parentName="ul"><em parentName="li">{`Domain`}</em>{` on which you will be communicating with Criipto Verify. Could be for example `}<inlineCode parentName="li">{`samples.criipto.id`}</inlineCode></li>
      <li parentName="ul"><em parentName="li">{`Client secret`}</em>{` which Okta needs to fetch actual user information from Criipto Verify during login.
The secret is generated and copied as described further down.`}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "680px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "69.79166666666667%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAABYlAAAWJQFJUiTwAAABGUlEQVQ4y62T627DIAyFef/nnLqkLVdzB+dMEC3r/i1ZkT4dG9kWso3QRkFrhZL9WxCtFliK2LYNwM6wr9BqhsjJgzwhpXDQarpELQFCa4mP2w3LuuJzWbDe7/DeIHgz9S/MWDJI0UGQM5DyASWfUPIx+6mVgjrBHi9BZCCc01BaQiqJMaCcHHJ0u55h5hDE6Jl1Hto6GEsoJYN7Rb9ALRGi5AgKEeQDnA9IOWPjDu4dzOeoNUFYIxG8nQ0dTy6Zpl5FWGdwf0o8lYSxZo6+fJPP8rLYFNJU5xO0DYc9dOBjhnH7vaEI7QKY+fgI48zF7q2CeUNn/qHzb595Jr/qiJmFtpeCbRTsFe86rRWIWhJ6K9P5D6PGWJsvzKBDZTX1SaMAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Register Application",
          "title": "Register Application",
          "src": "/static/1bfc103f72343b1cd4a1d73aa6291cd1/c5bb3/okta-register-application.png",
          "srcSet": ["/static/1bfc103f72343b1cd4a1d73aa6291cd1/8514f/okta-register-application.png 192w", "/static/1bfc103f72343b1cd4a1d73aa6291cd1/804b2/okta-register-application.png 384w", "/static/1bfc103f72343b1cd4a1d73aa6291cd1/c5bb3/okta-register-application.png 680w"],
          "sizes": "(max-width: 680px) 100vw, 680px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <p>{`If you plan on using single-signon, you must also register your Okta `}<inlineCode parentName="p">{`post_logout_redirect_url`}</inlineCode>{` here so you can run single-logouts.`}</p>
    <h2>{`Configure the OAuth2 code flow`}</h2>

    <OAuth2CodeFlowSnippet mdxType="OAuth2CodeFlowSnippet" />
    <h2>{`Create Okta identity provider`}</h2>
    <p>{`Make sure you are in `}<inlineCode parentName="p">{`Classic UI`}</inlineCode>{` mode, and click on the `}<inlineCode parentName="p">{`Security -> Identity Providers`}</inlineCode>{` item.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "681px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "40.625%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAABYlAAAWJQFJUiTwAAABcUlEQVQoz42Pz0tUURzF76agPyRjogRJ2rTrr3ASXAa5tYIWMchYk+UmCFy1r4cLlZwZHmbYRkFoNTPGBC0aKNMKfff9uPe+e99H3nsmEU34vRzO+d7v9x7OFVcaLS4/bjH6pM3FepNKo8347CrX59uMPfW5NLfGSH3tlE/1SX+10WJ8wefaM5/KoybifG2T0Rc7nKttsrg1YKn/i5cLb7gwvYJ4+B5xb73E3Zzflvitc77/B8+sI24vf+SB/5k7q312v4fsS4X/6QfV1x0mvW7BOSZedf7SXapej0mvR9XrcsvrFffCqAQZBOg45NvPQ/YOQ2Jj+W9llkwn/xyJOFEEMiSKY5I4whoDWYZ1bii0MQShRMYhNnO4Yj8jtRZhrUVKiVIKYwxa60IPQ6ISjDJkeUB1Erg4FO9FmqaFSW58FjjrCNQRH4622Yo2+Jp+AQcuT651aZg3ZylHubcdvGNicIOpwU2eH9RQkUaG5S+PAbNJH1+SVCJ1AAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Identity Providers",
          "title": "Identity Providers",
          "src": "/static/ba62e08d137162e7c53e025652fd2520/8ce52/okta-identity-providers.png",
          "srcSet": ["/static/ba62e08d137162e7c53e025652fd2520/8514f/okta-identity-providers.png 192w", "/static/ba62e08d137162e7c53e025652fd2520/804b2/okta-identity-providers.png 384w", "/static/ba62e08d137162e7c53e025652fd2520/8ce52/okta-identity-providers.png 681w"],
          "sizes": "(max-width: 681px) 100vw, 681px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <p>{`and click on the `}<inlineCode parentName="p">{`Add Identity Provider`}</inlineCode>{` button, select `}<inlineCode parentName="p">{`OIDC`}</inlineCode>{` for protocol.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "411px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "123.95833333333333%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAZCAYAAAAxFw7TAAAACXBIWXMAABYlAAAWJQFJUiTwAAAEC0lEQVQ4y6VU228UVRg/vSVe0iix8qKv6JMx0agxQuKDf4Uv+mDwQYxiu/QiFhDTWl8UK15Kog+U0kIrJhqhFCluu73sykVqEFjMLt3d2dmZndmZ2dvcf+Y7u7PZFlQST/LLd53f+b7vnDNs98koDvx0BT0zMYS+/w090zF0T8fQd+oix66pVfT/cJHHOGZi2DMdRWg6iu76N8RBfpKMvfEdtvaeRNuucTzUM4nO3RNoe3scD747gfveOQb22hF07TmBLRTrnkRXaArsvRO4v3eG+x4OTfL8xwem0frWUbD2gbNo6Z9FS98sWN8ZtPbPgvvI7v4ZrOd0DaHTNTt0Bs+9/i2e2jkOtvcc2uv5HQNnuWRsbxjt+xfQ+VEEW4aW0LpvAeyDMDr2L+CTxTRO/ang+JqMyTUZU9cUjF8SIWx7FRNP7gQ7uIy2wTBaB8Ngg2G0kHxkKIIvVtIYiwk4OJ/ESHgd2z5dRce+X5HSqoDvwbIcWKYNy7Jhw4f44zJefPM4OkdW8NjIEh4djmDrcARdwxEw9v4FPP/NJTzxWZRj+5EreODDRbCBC7gqluC6HoqmA6NqQ6/aMG0XCQdo/XgVrOcXsP55sN7zYH3zYH3nwV76KoqnDy3jmdFlPDu6wvUXvlzFy2MxHJiL4/BSEocWEhhdTODzxQQOR5IYmruJV8aioG93fB3F9iYw3HX5HBXLQdG0USJZtblOlZYtp5GzeTEf97D8f87yfJ+HvTp4hefm5nDt+g2oeQXr6+uQJBnpVAqpdBoFTeO+XE5CPq8gJ4q4nbyNbDYLTdPq+/kNcMJj40dx+fc1mNUqBEGAqhag6xoEIcsJK5UKVFVFoVDgksiI3DCMJsKgZf+emv6XafgbdEbUnufdkbA5MUCzfTdyfiie78F2HLieC8d1YVkWXM/juuu69cHXCLjtedwm6TjOhg1q18YD5GwWUjYHieaWV6BKEnKCCDEjAI7bqKJoGMjn8/WZalAUhetBl8zxXGTKGoSKzpGu65m6TUiVNZQc6z/nx1u2PBdxQ8ZNXUKcYMg1qUs1nyHjhi6hYJbhez5vOWib0KwTGP7n2nw4jFiNooFKtYpypcLvVrFYhLEJxSYEOeVyGaZZRalUaoDRKZHy161b/EWQThdY13T+EnRdh65pnIRsIqrwjYvI52Ukkkm+Ic/TdTDbtmGaFqKrK7i69gd/JblcDrIkQRRzXFdVhZ+sIGSgKGqjunRqHdFoFLpRq5rAaKhUUbFU4olB6UFCsLNBqPsCP0nKbXRCFVLLpJCT7lQmk4EoilBUteFvBuVQXJblhi8g44RUYeCk1uLxOAf9XYK5NX9AhDTvRCIJTdM3xBqEpmk2QBUT6Pk1+5tBc6/N/s7Y3x+i4/AMmpEDAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Add identity provider",
          "title": "Add identity provider",
          "src": "/static/b091ebfa9bc0737d9e746960e45eedb9/2a432/okta-add-identity-provider.png",
          "srcSet": ["/static/b091ebfa9bc0737d9e746960e45eedb9/8514f/okta-add-identity-provider.png 192w", "/static/b091ebfa9bc0737d9e746960e45eedb9/804b2/okta-add-identity-provider.png 384w", "/static/b091ebfa9bc0737d9e746960e45eedb9/2a432/okta-add-identity-provider.png 411w"],
          "sizes": "(max-width: 411px) 100vw, 411px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <p>{`Select `}<inlineCode parentName="p">{`Add OpenID Connect IdP`}</inlineCode></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "430px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "172.39583333333331%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAiCAYAAABfqvm9AAAACXBIWXMAABYlAAAWJQFJUiTwAAAEK0lEQVRIx6WWy4pjZRDHjw8gjLfF4APMTsSV4gOMIKNuxhdQFDfiS6jIuHfhYnAj4qCCouJiZqFgD7TYNM5Id7qT9Mmlk3O/5NxvP6kvOZmkk+5p8EDlq/NdKlX1/1d9R+OCp+Hip2k2d2hVVWHbNlVVU+Q58l6WJXleqA2z2Yy8KJR5mS+Kgrqul8ZkXDWr+Z7H57duYZg2veNjOp1D+ic6B4eHmKbBZHLKYDhC1wfouk6n06HX62MYxiODK55qZVnQ6/WUF+KdiGwQT7IsXx4S79p18bDddzZ07ZzkXJin1bVVD2XUzrp80cHLAKQ8zNIE0zBUXizTxHVcHNtmOp0SR9HyUJIkeJ5HOAsJwxDX9RRo9aqH8pIWObMkJhSJY+IsJYwjNSd6WdfqgOQtz3OVXxmVXpZroWt2FvGXM2TPHS/lb3fEnjfXZa0b2lA3S7qcFZlvReN/PFuJLXSI4ljRJk1TEiXJij6XdCGSRyVpqkKO4/jRXJKgCdeCwKfb7WLbDp7r4Hk+juviuS6ujL6P57n4vq9AmAPiMBqNlC7zQRAo0aTEAt/j3r27dHt9Bnqf0WhMv68rwsuhyWTCycmJmpfDYuR0PGZn5z6zKFKGZE5Ey7JMKbMoIcvzRR3Px1bkXfa1IqG1KWgNLT0sihzfD7j9/S4nQxPbnKhwRUzTVLyMk2QrKMJJicBxnKVR5aEk1o1y0jQjWyR7DkCq1oqi2FpqruPQk9yvGizLiiJPwbGpy2rJp7Plto2Dkg6Zb9uaiFY3UGUuu/t/ok8DjNMhlmWrcPXBQHl4Huekcs62Ya2WhiotaW1ps4tsk7aFrZWe/KRpwng4UujFcbRAMl2S9nEG17qNKGLg4LCDZVkY04kitGFaqvvI9XBRyBsGKwGhKh9bsy0oq6Bt9xBIg4R/dr/A8Pfx7BDTMjFNS1FBPBQUt3m51SB1hZ/BgXFEEen4QUQYBqqepW6FW3M0uVwO5fpsJOTm/HtYhdrUG3I+KHnGp599Qrd3hGFOmJpTBiMdawWQhvqyoFRQgT8NQW5NSZdEmM71imppIG0SamqiZsasDs/JYQN/xL/xzug6Hxhv8r55g/eM13nXeo23p69wY/QCb01e4qbxMtcn13hj/CKvDq/yoXtT/bGE3tCs33q/BHd49r7GtaMneG5P46l/Na7saVzZ13jmocbTIvsaVx9oPP9A48kDjY/Dj5AsVE21blAVd1XgMSXAZa/a4df8Dj8X3/Bt9iXfpbf5IfuKn7Kv2cnvsZv/zt30R+I6Uh5uhCwG86Li4bFF6MVUYUkTQWJlEMplDHUAzWzz06ysyu0Ghbg9w8FTRDZwA5fR6RDbswlmPpZr4wWuAqRuKgWUhLkVFDGoGmi9+SG0/DhajO0l3170qxW0QuxqUaPNRs2e12W2fSS1/fA/iWcqmHfc5zoAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "OIDC",
          "title": "OIDC",
          "src": "/static/5a8f5dddf06e3b22ada49b8b412c6603/05ed2/okta-add-oidc-identityprovider.png",
          "srcSet": ["/static/5a8f5dddf06e3b22ada49b8b412c6603/8514f/okta-add-oidc-identityprovider.png 192w", "/static/5a8f5dddf06e3b22ada49b8b412c6603/804b2/okta-add-oidc-identityprovider.png 384w", "/static/5a8f5dddf06e3b22ada49b8b412c6603/05ed2/okta-add-oidc-identityprovider.png 430w"],
          "sizes": "(max-width: 430px) 100vw, 430px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <p>{`Fill in the form with values for you Criipto Verify application, similar to the following example`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "681px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "100.52083333333333%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAABYlAAAWJQFJUiTwAAABy0lEQVQ4y41Uy5LjIAz0/1/8h3uaw2zKJkYI9IinNCVsEj+SSQ5dxAE1UktNx8KdiPTEZMSsxGzMbCJywqv/t/sdMzv6CcFCihogGmAyIrJcyPK6Mj+C/kJXSqmEMUaLU1SIYI4pgo0RK74DGOZiTPQ+w0Y4huBZqarWTdUFwmyIyQCgrrfb7SU8rmOmqqFniIh6LMs1TYgVvjfPcw32i4+oGRLRouE0WQhBSykPrVojmIypmO81+JmnGZayEA7DYDHGXYb+OxPb1zDZmIrpRi/P6EXJ3mXpaRF8R+gEkMn+XcD+A/2p345QmHsvw5tyzLAUMszZCtHbkdlpGEIwSOlccs6WEe+ivyUUkUpYOyWqxwPbGfuIsGoo0kNBmxDULVgJhJ/a7WOnXKer63Qf7EbkzUopVfhwu9ZvB7tZDyCpB+I6xI3Qvxtc0zaLvtfg36fBHoZBvTlOuiVs2TX45Qmgkm8vO3X5crmoB7SOHglb6atNqw232M2hHwrXq/qtTcc9YboTLqRQ97ZnTyWP46jjONaA5tdnnm2Y55v9zPMdG+stTsk51wxpdcWrV/phy2JDRAuAdcVCy2D787Ue1k9nToSNiHevuj91v2xy95gMlk+CAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Example identity provider",
          "title": "Example identity provider",
          "src": "/static/27de94058fd4050a88851e9bbd73d0a7/8ce52/okta-add-criipto-verify-example-identityprovider.png",
          "srcSet": ["/static/27de94058fd4050a88851e9bbd73d0a7/8514f/okta-add-criipto-verify-example-identityprovider.png 192w", "/static/27de94058fd4050a88851e9bbd73d0a7/804b2/okta-add-criipto-verify-example-identityprovider.png 384w", "/static/27de94058fd4050a88851e9bbd73d0a7/8ce52/okta-add-criipto-verify-example-identityprovider.png 681w"],
          "sizes": "(max-width: 681px) 100vw, 681px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <p>{`Given the values above, and assuming that your Criipto Verify domain is `}<inlineCode parentName="p">{`acme-corp.criipto.id`}</inlineCode>{` you must add`}</p>
    <ol>
      <li parentName="ol"><em parentName="li">{`Client ID`}</em>{`: `}<inlineCode parentName="li">{`urn:criipto:verify`}</inlineCode>{` (if you have set a different value in your Criipto Verify `}<inlineCode parentName="li">{`Client ID/Realm`}</inlineCode>{`, use that value instead here - they must match)`}</li>
      <li parentName="ol"><em parentName="li">{`Client Secret`}</em>{`: The secret generated for you by Criipto Verify during the `}<inlineCode parentName="li">{`OAuth code flow`}</inlineCode>{` setup`}</li>
      <li parentName="ol"><em parentName="li">{`Scopes`}</em>{`: `}<inlineCode parentName="li">{`openid`}</inlineCode>{` will suffice`}</li>
      <li parentName="ol"><em parentName="li">{`Issuer`}</em>{`: `}<inlineCode parentName="li">{`https://acme-corp.criipto.id`}</inlineCode></li>
      <li parentName="ol"><em parentName="li">{`Authorization endpoint`}</em>{`: `}<inlineCode parentName="li">{`https://acme-corp.criipto.id/oauth2/authorize?acr_values=urn:grn:authn:itsme:basic`}</inlineCode></li>
      <li parentName="ol"><em parentName="li">{`Token endpoint`}</em>{`: `}<inlineCode parentName="li">{`https://acme-corp.criipto.id/oauth2/token`}</inlineCode></li>
      <li parentName="ol"><em parentName="li">{`JWKS endpoint`}</em>{`: `}<inlineCode parentName="li">{`https://acme-corp.criipto.id/.well-known/jwks`}</inlineCode></li>
    </ol>
    <p>{`The `}<em parentName="p">{`Name`}</em>{` is entirely up to you, and you don't have to specify the optional `}<em parentName="p">{`Userinfo endpoint`}</em>{` if you at the same time ensure that you configure your Criipto Verify application to use `}<inlineCode parentName="p">{`fromTokenEndpoint`}</inlineCode>{` in the `}<inlineCode parentName="p">{`User info response strategy`}</inlineCode>{` dropdown.`}</p>
    <Highlight icon="file-lines" mdxType="Highlight">
      <p>{`  This setup assumes you are looking to authenticate your users with `}<inlineCode parentName="p">{`Itsme`}</inlineCode>{` in `}<inlineCode parentName="p">{`basic`}</inlineCode>{` mode. If you have other needs, replace the value of the `}<inlineCode parentName="p">{`acr_values`}</inlineCode>{` parametery in the `}<em parentName="p">{`Authorization endpoint`}</em>{`, or set up several `}<em parentName="p">{`Identity Providers`}</em>{` in your Okta tenant - see below for a list of all supported values.`}</p>
    </Highlight>
    <h3>{`Supported acr_values`}</h3>

    <LoginMethodsSnippet mdxType="LoginMethodsSnippet" />
    <Highlight icon="file-lines" mdxType="Highlight">
      <p>{`  You can `}<a parentName="p" {...{
          "href": "https://developer.okta.com/docs/guides/add-an-external-idp/openidconnect/configure-idp-in-okta/"
        }}>{`find more details here`}</a></p>
    </Highlight>
    <h3>{`Test users`}</h3>

    <TestUsersSnippet mdxType="TestUsersSnippet" />
    <h2>{`Integrate your own application with Okta`}</h2>
    <p>{`How to integrate your application with Okta depends on the technology you are working with. Refer to the `}<a parentName="p" {...{
        "href": "https://developer.okta.com/docs/"
      }}>{`Okta developer documentation`}</a>{` for more details.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      